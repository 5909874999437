* {
    margin : 0;
    padding: 0;
}

html,
body {
    overflow: hidden;
}

.webgl {
    position: fixed;
    top     : 0;
    left    : 0;
    outline : none;
}

.title {
    position       : absolute;
    font-family    : courier, courier new, serif;
    display        : flex;
    justify-content: center;
    width          : 100%;
    padding-top    : 5vh;
    font-size      : 5vh;
}

.later {
    position       : absolute;
    bottom         : 0;
    font-family    : courier, courier new, serif;
    display        : flex;
    justify-content: center;
    width          : 100%;
    padding-bottom : 5vh;
    font-size      : 3vh;
}